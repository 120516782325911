// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2pIw-iciKlHbmCTmn5XXjf{background:transparent}._1h_2DWByi0TOAD2Ekj6481{display:flex;flex-flow:row wrap;justify-content:space-evenly;align-items:center;max-width:1328px;margin-left:auto;margin-right:auto;width:100%}._8s82Y3jg1u5KH3Ct-nMWT{display:flex;justify-content:space-between;padding:16px}.zG5Zdh0VCDjJeZEV6bx6_{flex-direction:column;align-items:center;gap:8px}._2_zX90ZQv-PgcCLQhQBDCR{display:flex;flex-direction:column;gap:8px}._3rYARGMS2kvcRTqSNfM0a_{text-transform:uppercase}.n6j7ebICGGwr8o5dpz8MY{background:none;cursor:pointer;text-decoration:none}.n6j7ebICGGwr8o5dpz8MY:hover,.n6j7ebICGGwr8o5dpz8MY:focus,.n6j7ebICGGwr8o5dpz8MY:active{color:inherit;text-decoration:underline}._2xurY-wxBKDjdSwd0Xq6zG{display:flex;padding:12px}._16lCfFm-GeHrChWtlHsGg7{line-height:1;padding:12px}._3PHAqW77ZYKbDOvxzAlrOa{width:16px;height:16px}._2-Fss9U_QQTqDtL0zK32Kj{filter:grayscale(100%) opacity(100%) brightness(60%)}._3T03vgmzJcpioASHbOnqoN{filter:grayscale(100%) opacity(100%) brightness(120%)}.F9Tl1ezP-c3pYB0ZWFbW9{filter:grayscale(100%) opacity(100%) brightness(80%)}@media screen and (min-width: 960px){.zG5Zdh0VCDjJeZEV6bx6_{flex-direction:row}}\n", ""]);
// Exports
exports.locals = {
	"footer": "_2pIw-iciKlHbmCTmn5XXjf",
	"footerContainer": "_1h_2DWByi0TOAD2Ekj6481",
	"footerSection": "_8s82Y3jg1u5KH3Ct-nMWT",
	"infoLinksSection": "zG5Zdh0VCDjJeZEV6bx6_",
	"chinaLinks": "_2_zX90ZQv-PgcCLQhQBDCR",
	"text": "_3rYARGMS2kvcRTqSNfM0a_",
	"link": "n6j7ebICGGwr8o5dpz8MY",
	"socialLinks": "_2xurY-wxBKDjdSwd0Xq6zG",
	"socialLink": "_16lCfFm-GeHrChWtlHsGg7",
	"socialIcon": "_3PHAqW77ZYKbDOvxzAlrOa",
	"twitterIcon": "_2-Fss9U_QQTqDtL0zK32Kj",
	"fbIcon": "_3T03vgmzJcpioASHbOnqoN",
	"instagramIcon": "F9Tl1ezP-c3pYB0ZWFbW9"
};
module.exports = exports;

import { type ReactElement, type ReactNode } from "react";
import { GeoCountryProvider } from "components/GeoCountryProvider";
import { LangProvider } from "components/LangProvider";
import { ChinaOptionsProvider } from "components/ChinaOptionsContext";
import { VersionProvider } from "components/VersionProvider";
import { AppNameProvider } from "js/tracking/AppNameContext";
import useMeMaybe from "hooks/useMeMaybe";

/**
 * Provider for both auth and non-auth pages.
 */
export default function CamblyCommonProvider({ children }: { children: ReactNode }): ReactElement {
  const geoCountry = cambly.GEO_COUNTRY;
  // Admin users don't have a language set so we need to fall back to English
  const locale = cambly.GET_LOCALE ?? "en";

  const me = useMeMaybe();

  return (
    <AppNameProvider name="express">
      <VersionProvider versionName={cambly.WEB_VERSION}>
        <GeoCountryProvider geoCountry={geoCountry}>
          <LangProvider lang={locale}>
            <ChinaOptionsProvider
              chinaCDNTag={cambly.fromChinaCDN}
              isKoudaiyingwen={document.location.host.includes("koudaiyingwen")}
              userLang={me?.language}
              userGeoCountry={me?.geoCountry}
            >
              {children}
            </ChinaOptionsProvider>
          </LangProvider>
        </GeoCountryProvider>
      </VersionProvider>
    </AppNameProvider>
  );
}

import { type UserRole } from "model/role";
import { createContext, useContext } from "react";

type AuthorizationContextType = { userId?: string; role?: UserRole };

// Provides authorization parameters for server calls
// This should generally be provided near the root
// of all Apps, but can also be used to provide special
// context in sub-components. e.g., we might want
// to show the admin search-box to support users on
// the student page - We could wrap the search box
// with a different Authorization Context so that
// server requests made by that component have the
// correct parameters (e.g. scrub levels).
const AuthorizationContext = createContext<AuthorizationContextType>({});
AuthorizationContext.displayName = "AuthorizationContext";

export function useAuthorization(): AuthorizationContextType {
  const context = useContext(AuthorizationContext);
  if (context === undefined) {
    throw new Error("useAuthorization must be used within a AuthorizationContext provider");
  }
  return context;
}

export default AuthorizationContext;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1WzSIGKUjnKuOyOr5nRj6C{text-transform:none;font-size:14px;font-weight:700;margin:0 10px;text-decoration:none}._1WzSIGKUjnKuOyOr5nRj6C:hover{color:#fff}.P4qa2iV-cYydewMbnSToa,.P4qa2iV-cYydewMbnSToa:hover{color:#353535}\n", ""]);
// Exports
exports.locals = {
	"buttonStyle": "_1WzSIGKUjnKuOyOr5nRj6C",
	"textButtonStyle": "P4qa2iV-cYydewMbnSToa"
};
module.exports = exports;
